// FORTUNA EHRENFELD SKIN <- BLUMFELD SKIN <- JIRAFA SKIN
// @import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;700&display=swap');

body {
  color: #2b2b2b;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

// NAV

.navbar-light {
  background: transparent !important;
  transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  body[data-scrolled] & {
    background-color: $white !important;
  }
}

.product-listing-page .navbar-brand {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.1s;
  transition-delay: 0;
}
body[data-scrolled] {
  .navbar-brand {
    opacity: 1;
    transition-delay: 0.2s;
    transform: translateY(0);
  }
}

#header .navbar .navbar-brand {
  img {
    height: 2.5rem;
    max-height: 2.5rem;

    @include media-breakpoint-up(md) {
      height: 3.5rem;
      max-height: 3.5rem;
    }

    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}

.navbar .hamburger {
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: $white, $alpha: 0.97) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  .card-title {
    text-align: center;

    .sub-title {
      font-size: smaller;
    }
  }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.blurry-background-image:before {
  background-image: none;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $body-bg;
  color: $primary;
  border-bottom: 10px solid $primary;
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}
